import React from 'react';
import {render} from 'react-dom';
import PrivacyCenterLayer from '../components/privacy-center';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('personalization/privacy-center-footer-link');

let privacyLayerFooterLink = moduleScope.querySelector('[jsh-modal-layer-link]');
if (privacyLayerFooterLink) {
    if (config.hurraConsentEnabled) {
        privacyLayerFooterLink.addEventListener('click', () => {
            window.hc_consent.push("show_modal");
        });
    } else {
        privacyLayerFooterLink.addEventListener('click', openPrivacyCenter.bind(this));
    }
}

function openPrivacyCenter() {
    const privacyCenterLayerNode = document.createElement('div');
    privacyCenterLayerNode.setAttribute('jsh-privacy-center-layer', '');
    document.body.appendChild(privacyCenterLayerNode);

    render(
        <PrivacyCenterLayer centerDataUrl={config.privacyCenterDataUrl} pageViewId={config.pageViewId}/>,
        privacyCenterLayerNode
    );
}